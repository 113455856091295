:root {
    --background-color: #EEEEFF;
    --yellow: #FFD166;
    --dark-yellow: #B29144;
    --green: #06D6A0;
    --dark-green: #068E6A;
    --blue: #118AB2;
    --dark-blue: #0A5872;
    --red: #EF476F;
    --dark-red: #A9304C;

    --button: #403A45;

    --popup-green-background: #F0FDF4;
    --popup-green-title: #166534;
    --popup-green-text: #15803D;

    --popup-red-background: #FEF2F2;
    --popup-red-title: #991B1B;
    --popup-red-text: #991B1B;

    --popup-yellow-background: #F0FDF4;
    --popup-yellow-title: #CA8A04;
    --popup-yellow-text: #D6A439;

    --shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  