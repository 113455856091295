.brandedButton {
    width: 100%;
    background-color: var(--button);
    color: white;
    font-weight: 700;
    letter-spacing: .5px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    padding: 16px;
    margin: 8px 0;
}

.backButton {
    color: var(--button);
}

.backButton svg {
    color: var(--button)
}

.categoryText {
    position: relative;
    width: 70px;
    /* background-color: red; */
}

.backHomeButton {
    background-color: white;
    height: 50px;
    width: 130px;
    cursor: pointer;

    padding: 0 12px;
    border-radius: 30px;
    border: none;

    box-shadow: var(--shadow);
}

/* category Selector */

.categorySelector label {
    display: block;
    margin-bottom: .3rem;
    font-size: .9rem;
    font-weight: bold;
    color: #05060f99;
    transition: color .3s cubic-bezier(.25, .01, .25, 1) 0s;
}

.categorySelector select {
    width: 100%;
    height: 44px;
    background-color: var(--background-color);
    border-radius: .5rem;
    padding: 0 1rem;
    border: 2px solid transparent;
    font-size: 1rem;
    transition: border-color .3s cubic-bezier(.25, .01, .25, 1) 0s, color .3s cubic-bezier(.25, .01, .25, 1) 0s, background .2s cubic-bezier(.25, .01, .25, 1) 0s;
}

/* colorSelector */

.colorSelector {
    padding-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}