.actions {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
  }
  
  .action {
    background-color: white;
    display: block;
    width: fit-content;
    height: fit-content;
    /* margin-right: 3.5em; */
    border-radius: 50px;
    box-shadow: var(--shadow);
    padding-bottom: 3em;
    margin-left: 1em;
  }

  .roundbutton {
    background-color: white;
    padding: 12px;
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    border: none;
    box-shadow: var(--shadow);
    cursor: pointer;
  }

  .button {
    display: block;
    width: 90px;
    height: 30px;
    margin: 0 1.25em;
    border-radius: 10px;
    border: 1px var(--button) solid;
    color: var(--button);
    box-shadow: var(--shadow);
    background-color: var(--background-color);
    cursor: pointer;
  }