@import './colors.css';

.navbar {
    background-color: white;
    margin: 20px;
    position: absolute;
    left: 0;
    height: fit-content;
    width: 110px;
    display: block;
    justify-content: center;
    border-radius: 80px;
    padding-bottom: 70px;
    box-shadow: var(--shadow);
}

.rethinkr-logo {
    width: auto;
    height: 100px;
    margin-top: 24px;
    margin-bottom: 12px;
}

.navbar-top {
    background-color: transparent;
    text-align: center;
    border-radius: 80px;
    padding-bottom: 12px;
    box-shadow: var(--shadow)
}

.navbar-top button {
    cursor: pointer;
    background-color: transparent;
    padding: 1.2em;
    border-radius: 50%;
    margin-bottom: 8px;
    border: transparent;
    transition: transform 0.2s ease;
}

.navbar-top button:hover {
    transform: scale(1.1);
}

.navbar-top button .icon {
    color: var(--button);
    scale: 1.5;
}

.navbar-bot {
    z-index: 0;
}

.user-pp {
    margin-top: 24px;
    width: 65px;
    border-radius: 50%;
}

.language .toggle-container {
    margin-top: 1.25em;
}

.theme .toggle-container {
    margin-top: 1.25em;
}

.manageMenu {
    position: absolute;
    height: 100px;
    width: 18px;
    background-color: white;
    border: none;
    border-radius: 0 10px 10px 0;
    right: -18px;
    top: 40%;
    cursor: pointer;
    box-shadow: 5px 5px 5px -2px rgba(99, 99, 99, 0.2);
}

.rotate-icon {
    transition: transform 0.3s ease-in-out;
}

.rotate-icon.open {
    transform: rotate(180deg);
}

.logout {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    border: none;
    /* padding: 12px; */
    border-radius: 50%;
    background-color: var(--background-color);
    cursor: pointer
}