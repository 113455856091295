.board {
    padding-top: 100px;
    padding-left: 180px;
    padding-right: 100px;
    height: 90vh;
}

.boardContent {
    display: flex;
    flex-wrap: wrap;
}

.boardItem {
    padding: 0 50px;
}

.note {
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    font-size: 12px;
    box-shadow: var(--shadow);
}

.category {
    margin-top: 70px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    width: 50px;
    height: 50px;
    box-shadow: var(--shadow);
}

.category::before {
    content: '';
    padding: 10px;
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 50px;
    height: 50px;
}

.category-blue {
    background-color: var(--dark-blue);
}

.category-blue::before {
    background-color: var(--blue);
}

.category-yellow {
    background-color: var(--dark-yellow);
}

.category-yellow::before {
    background-color: var(--yellow);
}

.category-red {
    background-color: var(--dark-red);
}

.category-red::before {
    background-color: var(--red);
}

.category-green {
    background-color: var(--dark-green);
}

.category-green::before {
    background-color: var(--green);
}