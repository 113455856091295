.arc-text {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    text-align: center;
    line-height: 200px;
    background-color: red;
}

.arc-text span {
    display: inline-block;
    position: absolute;
    top: -135px;
    transform-origin: 0 90%;
    font-size: 20px;
    font-weight: bolder;
}

.arc-text span::selection {
    background: none;
}