.input {
    /* max-width: 190px; */
    height: 44px;
    background-color: var(--background-color);
    border-radius: .5rem;
    padding: 0 1rem;
    border: 2px solid transparent;
    font-size: 1rem;
    transition: border-color .3s cubic-bezier(.25, .01, .25, 1) 0s, color .3s cubic-bezier(.25, .01, .25, 1) 0s, background .2s cubic-bezier(.25, .01, .25, 1) 0s;
}

.label {
    display: block;
    margin-bottom: .3rem;
    font-size: .9rem;
    font-weight: bold;
    color: #05060f99;
    transition: color .3s cubic-bezier(.25, .01, .25, 1) 0s;
}

.input:hover,
.input:focus,
.input-group:hover .input {
    outline: none;
    border-color: #05060f;
}

.input-group:hover .label,
.input:focus {
    color: #05060fc2;
}

/* TEXT AREA */

.textarea {
    max-width: 190px;
    height: 100px;
    background-color: var(--background-color);
    border-radius: .5rem;
    padding: 0 1rem;
    border: 2px solid transparent;
    font-size: 1rem;
    transition: border-color .3s cubic-bezier(.25, .01, .25, 1) 0s, color .3s cubic-bezier(.25, .01, .25, 1) 0s, background .2s cubic-bezier(.25, .01, .25, 1) 0s;
    resize: none;
}

.textarea:hover,
.textarea:focus,
.textarea-group:hover .textarea {
    outline: none;
    border-color: #05060f;
}

.textarea-group:hover .label,
.textarea:focus {
    color: #05060fc2;
}

/* KEYWORDS */

.keywords-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
  }
  
  .keywords-input {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--background-color);
    border-radius: 5px;
  }
  
  .suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 120px;
    overflow-y: auto;
    border: 1px solid var(--background-color);
    border-radius: 5px;
    background-color: #fff;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: var(--background-color);
  }
  
  .selected-keywords {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .keyword-tag {
    background-color: var(--background-color);
    color: var(text-color);
    padding: 5px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  
  .remove-btn {
    background: none;
    border: none;
    color: var(--text-color);
    margin-left: 5px;
    cursor: pointer;
    scale: 2;
  }
  
  .remove-btn:hover {
    color: var(--red);
  }
  
  