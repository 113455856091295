.top {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: red; */
}

.searchbar-bg {
  width: 540px;
  height: 100px;
  clip-path: path("M65 25.5C45.09 6.18 30.246 1.643 0 0h539.5c-27.985 2.041-40.364 8.707-59.5 25.5l-70 59c-15.503 8.887-25.75 12.606-48 16H187c-21.447-1.852-32.307-5.317-49.5-16L65 25.5Z");
  background-color: white;
}

.searchbar {
  background-color: var(--background-color);
  width: 200px;
  height: 50px;

  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 12px;
  border-radius: 30px;
}

.searchbar input {
  border: none;
  outline: none;
  height: 32px;
  padding-left: 10px;
  flex-grow: 1;
  background-color: var(--background-color);
}

.search-icon {
  width: 16px;
  height: 16px;
}

.searchbar .icon {
  color: var(--button)
}

.top .backHomeButton {
  position: absolute;
  left: -100px;
  top: 20px;
}

.top .actions {
  position: absolute;
  right: -100px;
  top: 20px;
}