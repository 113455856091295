@import './Component/colors.css';

html, body {
  margin: 0;
}

.App {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  height: 100vh;
}